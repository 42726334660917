<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M7.33398 11.8334H8.66732V7.83337H7.33398V11.8334ZM8.00065 6.50004C8.18954 6.50004 8.34787 6.43615 8.47565 6.30837C8.60343 6.1806 8.66732 6.02226 8.66732 5.83337C8.66732 5.64448 8.60343 5.48615 8.47565 5.35837C8.34787 5.2306 8.18954 5.16671 8.00065 5.16671C7.81176 5.16671 7.65343 5.2306 7.52565 5.35837C7.39787 5.48615 7.33398 5.64448 7.33398 5.83337C7.33398 6.02226 7.39787 6.1806 7.52565 6.30837C7.65343 6.43615 7.81176 6.50004 8.00065 6.50004ZM8.00065 15.1667C7.07843 15.1667 6.21176 14.9917 5.40065 14.6417C4.58954 14.2917 3.88398 13.8167 3.28398 13.2167C2.68398 12.6167 2.20898 11.9112 1.85898 11.1C1.50898 10.2889 1.33398 9.42226 1.33398 8.50004C1.33398 7.57782 1.50898 6.71115 1.85898 5.90004C2.20898 5.08893 2.68398 4.38337 3.28398 3.78337C3.88398 3.18337 4.58954 2.70837 5.40065 2.35837C6.21176 2.00837 7.07843 1.83337 8.00065 1.83337C8.92287 1.83337 9.78954 2.00837 10.6007 2.35837C11.4118 2.70837 12.1173 3.18337 12.7173 3.78337C13.3173 4.38337 13.7923 5.08893 14.1423 5.90004C14.4923 6.71115 14.6673 7.57782 14.6673 8.50004C14.6673 9.42226 14.4923 10.2889 14.1423 11.1C13.7923 11.9112 13.3173 12.6167 12.7173 13.2167C12.1173 13.8167 11.4118 14.2917 10.6007 14.6417C9.78954 14.9917 8.92287 15.1667 8.00065 15.1667ZM8.00065 13.8334C9.48954 13.8334 10.7507 13.3167 11.784 12.2834C12.8173 11.25 13.334 9.98893 13.334 8.50004C13.334 7.01115 12.8173 5.75004 11.784 4.71671C10.7507 3.68337 9.48954 3.16671 8.00065 3.16671C6.51176 3.16671 5.25065 3.68337 4.21732 4.71671C3.18398 5.75004 2.66732 7.01115 2.66732 8.50004C2.66732 9.98893 3.18398 11.25 4.21732 12.2834C5.25065 13.3167 6.51176 13.8334 8.00065 13.8334Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
