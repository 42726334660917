<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.54766 17.9999L3.84766 12.2999L5.27266 10.8749L9.54766 15.1499L18.7227 5.97495L20.1477 7.39995L9.54766 17.9999Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
