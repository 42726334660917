import { type VariantProps, cva } from "class-variance-authority";

export { default as Badge } from "./Badge.vue";

export const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2 py-0.5 text-[10px] font-medium transition-colors focus:outline-none focus:ring-[3px] focus:ring-sonic-blue/30 focus:ring-offset-0",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-sonic-blue text-white hover:bg-sonic-blue/80",
        discount:
          "border-transparent bg-sonic-tag text-sonic-yellow font-sans rounded-none text-[10px] py-[1px]",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;
